
import Footer from '../Footer.jsx'
import ResCard from './ResTeam.jsx'
import MyClass from '../Nav.jsx'


function MRgro(){
    return (
        <>
                <MyClass/>
                <ResCard/>
                <Footer/>                           
        </>
    )
}

export default MRgro;