import Compi from "./Compicard";
import Footer from "../Footer";
import Chero from "./CompiHero";
import MyClass from "../Nav";


function Competition(){
    return(
        <>
        <MyClass/>
        <Chero/>
        <Compi/>
        <Footer/>
        </>

    )
}

export default Competition;